@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Nunito Sans", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Nunito Sans", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

*,
:after,
:before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a.nostyle:link {
  text-decoration: inherit;
  color: inherit;
  cursor: auto;
}

a.nostyle:visited {
  text-decoration: inherit;
  color: inherit;
  cursor: auto;
}

/* image dimensions to avoid layout shifts */
img {
  max-width: 100%;
  height: auto;
}

.animate-pulldown {
  animation: pulldown 1s ease-in-out 1.2s forwards;
}

.customUnderline {
  position: relative;
}

nav .customUnderline-active:before {
  visibility: visible;
  transform: scaleX(1);
}

.customUnderline:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #fff;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.2s ease-in-out;
}

@media (max-width: 768px) {
  .customUnderline:before {
    width: 30%;
    height: 2px;
  }
}

.customUnderline:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

.acc-cont {
  height: 1150px;
}

@media (min-width: 768px) {
  .acc-cont {
    height: 868px;
  }
}

.animate-appearup,
.animate-appearup-2 {
  animation: appearup 0.8s ease-out 1s forwards;
}

.animate-appearup-2 {
  animation-delay: 1.2s;
}

.animate-moveleft {
  animation: moveleft 0.8s ease-out 0.5s forwards;
}

.animate-moveup {
  animation: moveup 0.8s ease-out 0.3s forwards;
}

.animate-cookie .CookieConsent {
  justify-content: center !important;
  opacity: 0;
  align-items: center;
  color: white;
  /* justify-content: space-between; */
  left: 0px;
  position: fixed;
  width: 100%;
  z-index: 1100;
  font-size: 16px;
  /* padding-left: 40px;padding-right: 40px;padding-top: 20px; */
  bottom: 0px;
  animation: moveup 0.8s ease-out 3s forwards;
}

.animate-cookie .CookieConsent button {
  background: rgb(3, 56, 171);
  border: 0px;
  border-radius: 16px;
  box-shadow: none;
  color: white;
  cursor: pointer;
  /* flex: 0 0 auto;  */
  padding: 6px 16px;
  margin-left: 8px;
  /* margin-bottom: 4px; */
  font-size: 15px;
}

.animate-grow {
  animation: grower 3s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.animate-movearound {
  animation: movearound 15s linear infinite;
}

.curved-img {
  border-top-right-radius: 40px;
  border-bottom-left-radius: 40px;
}

.team::-webkit-scrollbar {
  width: 0px;
}

/* Track */
.team::-webkit-scrollbar-track {
  width: 0px;
}

/* Handle */
.team::-webkit-scrollbar-thumb {
  width: 0px;
}

.arrow {
  left: 12.5%;
  animation: dragleft 5s infinite linear;
}

@media (min-width: 768px) {
  .gray-bg {
    animation: grayer 5s infinite linear;
  }
}

.gray-bg--1 {
  animation-delay: 0.1s;
}

.gray-bg--2 {
  animation-delay: 1.65s;
}

.gray-bg--3 {
  animation-delay: 3.3s;
}

.gray-bg--4 {
  animation-delay: 4.9s;
}

.float {
  width: 35px;
  height: 35px;
  position: absolute;
  background-size: 100% 100%;
}

#tsparticles {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
}

.testimony {
  min-height: 340px;
}

@media (min-width: 768px) {
  .testimony {
    min-height: 290px;
  }
}

.needle {
  transform-origin: center;
  animation: spin 3.5 1;
  -webkit-animation: spin 3.5s 1;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.5, -0.5, 0.5, 1.5);
}

@keyframes appearup {
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes pulldown {
  from {
    transform: translateY(-100%);
    opacity: 0.2;
  }

  to {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes moveleft {
  from {
    opacity: 0.1;
    transform: translateX(60%);
  }

  to {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes moveup {
  from {
    opacity: 0.1;
    transform: translateY(60%);
  }

  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes dragleft {
  0% {
    left: 12.5%;
    background-color: rgb(255, 0, 191);
  }

  33% {
    left: 37.5%;
    background-color: rgb(89, 0, 255);
  }

  66% {
    left: 62.5%;
    background-color: rgb(0, 89, 255);
  }

  100% {
    left: 88.5%;
    background-color: rgb(10, 224, 10);
  }
}

@keyframes grayer {
  0% {
    background-color: rgb(124, 148, 184);
  }

  30% {
    background-color: rgb(124, 148, 184);
  }

  70% {
    background-color: transparent;
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes grower {
  0% {
    transform: scaleX(1);
  }

  50% {
    transform: scale3d(1.02, 1.02, 1.02);
  }

  100% {
    transform: scaleX(1);
  }
}

@keyframes movearound {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }

  20% {
    transform: translate(73px, -1px) rotate(36deg);
  }

  40% {
    transform: translate(141px, 72px) rotate(72deg);
  }

  60% {
    transform: translate(83px, 122px) rotate(108deg);
  }

  80% {
    transform: translate(-40px, 72px) rotate(144deg);
  }

  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}

/* external css: flickity.css */

* {
  box-sizing: border-box;
}

.bg-main {
  background-color: #091e31;
}

.video-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.video-container video.loaded {
  opacity: 1;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.video-bg {
  opacity: 0;
  animation: fadeIn 2s forwards;
}

.skeleton-loader {
  background-color: #e0e0e0;
  animation: pulse 1.5s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    background-color: #e0e0e0;
  }

  50% {
    background-color: #f0f0f0;
  }

  100% {
    background-color: #e0e0e0;
  }
}

.hamburger {
  display: inline-block;
  cursor: pointer;
  height: 20px;
  width: 22px;
  position: relative;
  z-index: 1001;
}

.line {
  background-color: #ffffff;
  height: 3px;
  width: 100%;
  display: block;
  position: absolute;
  left: 0;
  transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
}

.line1 {
  top: 0;
}

.line2 {
  top: 10px;
}

.line3 {
  top: 20px;
}

.hamburger.open .line1 {
  transform: rotate(45deg);
  top: 10px;
}

.hamburger.open .line2 {
  opacity: 0;
}

.hamburger.open .line3 {
  transform: rotate(-45deg);
  top: 10px;
}

.blog-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.blog-description {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.blog-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.headerGradient {
  background-image: linear-gradient(to right, #2f63ff, #030969);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.underlineEffect {
  height: 2px;
  background-image: linear-gradient(to right, #2f63ff, #030969);
  opacity: 0.75;
}

.important {
  color: #4f46e5;
  font-weight: bold;
}

.team-card {
  opacity: 0;
  transform: translateY(20px);
  transition: transform 0.5s ease-out, opacity 0.5s ease-out;
}

.team-animate-moveup {
  opacity: 1;
  transform: translateY(0);
}

.main-header {
  -webkit-text-stroke: 0.1px black;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.main-header-p {
  -webkit-text-stroke: 0.8px white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
}

@media (max-width: 900px) {
  .navbar-responsive {
    padding-left: 0;
    padding-right: 0;
  }

  .navbar-responsive-main {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (max-width: 1049px) {
  .navbar-responsive {
    padding-left: 0;
    padding-right: 0;
  }

  .navbar-responsive-main {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media screen and (max-width: 520px) {
  .leader-aside-section {
    width: 100%;
    height: 100%;
  }
}

.coming-soon-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 0;
}

.coming-soon-text {
  font-size: 1rem;
  font-weight: bold;
  color: #ffffff;
  animation: text-pulse 2s infinite ease-in-out;
  margin-right: 8px;
}

.coming-soon-dots {
  display: inline-flex;
  align-items: center;
}

.coming-soon-dots span {
  display: inline-block;
  background-color: #ffffff;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: 0 2px;
  opacity: 0;
  animation: dot-pulse 1.5s infinite ease-in-out;
}

.coming-soon-dots span:nth-child(1) {
  animation-delay: 0s;
}

.coming-soon-dots span:nth-child(2) {
  animation-delay: 0.2s;
}

.coming-soon-dots span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes text-pulse {
  0%,
  100% {
    color: #ffffff;
  }

  50% {
    color: #a0aec0;
  }
}

@keyframes dot-pulse {
  0%,
  100% {
    transform: scale(1);
    opacity: 0.2;
  }

  50% {
    transform: scale(1.5);
    opacity: 1;
  }
}

@media screen and (min-width: 1230px) {
  .img-wrap {
    visibility: visible;
  }

  .about-section-p {
    max-width: 42rem;
  }
}

@media screen and (max-width: 1230px) {
  .about-img-section {
    width: 100%;
  }

  .about-section-p {
    width: 100%;
  }
}

.blog-card img {
  position: relative;
}

.blog-card .tag {
  position: absolute;
  top: 8px;
  left: 8px;
  background-color: #2f63ff;
  color: white;
  padding: 4px 12px;
  border-radius: 9999px;
  font-size: 0.875rem;
}

@keyframes pulse-slow {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.7;
  }
}

.animate-pulse-slow {
  animation: pulse-slow 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.map-responsiveness {
  height: 26.3rem;

  @media (min-width: 766px) and (max-width: 1280px) {
    height: 30rem;
  }
}

.footer-container {
  @media (min-width: 1200px) {
    flex-wrap: nowrap;
  }

  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
}

.contact-form {
  width: 54%;

  @media (min-width: 770px) and (max-width: 1100px) {
    width: 80%;
  }

  @media (max-width: 770px) {
    width: 100%;
  }
}

.card-cou {
  display: flex !important;
}

.slick-list {
  margin-left: 15px !important;

  @media screen and (min-width: 1535px) {
    margin-left: 97px !important;
  }
}

.animate-wave {
  animation: wave 2s infinite;
  transform-origin: 70% 70%;
}

@keyframes wave {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  }
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.custom-list-marker {
  list-style-type: disc;
  list-style-position: inside;
}

.custom-list-marker li::marker {
  color: blue;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fadeInUp {
  animation: fadeInUp 0.5s ease-out forwards;
}
